// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import "../css/app.scss"

// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import deps with the dep name or local files with a relative path, for example:
//
//     import {Socket} from "phoenix"
//     import socket from "./socket"
//
import "phoenix_html"
import {Socket} from "phoenix"
import NProgress from "nprogress"
import {LiveSocket} from "phoenix_live_view"

let round = 0;

let Hooks = {}
Hooks.Hashes = {
    updated(){
        var current = document.getElementById('current').innerText;
        var localPlayers = localStorage.getItem('players');
        localPlayers = localPlayers.split(';');
        console.log("Looking for " + current +  " in " + localPlayers)
        if(localPlayers.indexOf(current) == -1){
            console.log("Hiding controls")
            document.getElementById('roll').setAttribute('style', 'display: none')
        }
        else{
            console.log("Showing controls")
            document.getElementById('roll').setAttribute('style', 'display: block')
            var currentRound = document.getElementById('currentRound').value;
            if(document.visibilityState != 'visible' && round != currentRound){
                notifyMe('Du bist dran!')
                round = currentRound;
            }
                
        }
    }
}

let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, {params: {_csrf_token: csrfToken}, hooks: Hooks})

// Show progress bar on live navigation and form submits
window.addEventListener("phx:page-loading-start", info => NProgress.start())
window.addEventListener("phx:page-loading-stop", info => NProgress.done())

// connect if there are any LiveViews on the page
liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)
window.liveSocket = liveSocket


function rollDice(){
    document.getElementById('roll').click();
}

document.addEventListener('keyup', function (e) {
    if(e.keyCode == 32){
        rollDice();
        e.preventDefault();
    }
});

function doJoin(){
    var localPlayers = localStorage.getItem('players');
    if(localPlayers === null)
        localPlayers = [];
    else
        localPlayers = localPlayers.split(';')

    var name = document.getElementById('username').value
    const crypto = require('crypto')
    var hash = crypto.createHash('md5').update(name + new Date().toString()).digest('hex')
    document.getElementById('userhash').value = hash;
    console.log("Joining: " + name + '/' + hash)

    localPlayers.push(hash)
    localStorage.setItem('players', localPlayers.join(';'))

    document.getElementById('doSubmit').click();
}

function notifyMe(string) {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(string);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                var notification = new Notification(string);
            }
        });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
}

window.doJoin = doJoin
window.notifyMe = notifyMe
